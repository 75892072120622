
.App-footer {
    width: 100%;
    position: absolute; /* Makes it stick to the bottom */
    bottom: 0;
    background-color: rgba(235, 235, 235, 1); /* Matches the page background */
    padding: 10px 0;
    display: flex;
    justify-content: center; /* Centers the content horizontally */
    align-items: center;
    border-top: 1px solid #ccc; /* Optional: Adds a top border for separation */
    font-size: 0.8rem; /* Slightly smaller text for footer */
    color: black;
    gap: 20px; /* Adds spacing between items */
}
  
.footer-link {
    color: #5e6f82; /* Adds a blue color for links */
    text-decoration: none; /* Removes underline */
}

.footer-link:hover {
    text-decoration: underline; /* Underline on hover for better UX */
}
  
  