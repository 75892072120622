.FAQ {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
    background-color: rgba(235,235,235);
    color: #333;
  }
  
  .FAQ-header {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .FAQ-header h1 {
    font-size: 2rem;
    color: #000;
  }
  
  .FAQ-header p {
    font-size: 1rem;
    color: #555;
  }
  
  .FAQ-content {
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    background: #fff;
  }
  
  .FAQ-item {
    padding: 15px 20px;
    cursor: pointer;
    border-bottom: 1px solid #ddd;
    transition: background-color 0.3s ease;
  }
  
  .FAQ-item:last-child {
    border-bottom: none;
  }
  
  .FAQ-item:hover {
    background-color: #f9f9f9;
  }
  
  .FAQ-question {
    font-size: 1rem;
    font-weight: 600;
    color: #0a2f54;
  }
  
  .FAQ-answer {
    margin-top: 10px;
    font-size: 0.9rem;
    line-height: 1.5;
    color: #555;
  }
  
  .FAQ-item.active .FAQ-question {
    color: #0056b3;
  }
  