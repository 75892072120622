/* General Header Styles */
.Header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 20px;
    background-color: rgba(235,235,235);
    /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); */
    position: sticky;
    top: 0;
    z-index: 1000;
  }
  
  .Header-logo {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  
  .Header-logo-image {
    height: 40px;
    margin-right: 10px;
  }
  
  .Header-title {
    text-decoration: none;
    font-size: 1.5rem;
    font-weight: bold;
    color: #000000;
  }
  
  /* Navigation Links */
  .Header-nav {
    display: flex;
    gap: 20px;
  }
  
  .Header-link {
    text-decoration: none;
    font-size: 1rem;
    color: #333;
    transition: color 0.3s ease;
  }
  
  .Header-link:hover {
    color: #007bff;
  }
  
  /* Hamburger Menu for Mobile */
  .Header-menu-button {
    display: none;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
  }
  
  .Header-menu-button:focus {
    outline: none;
  }
  
  /* Responsive Styles */
  @media screen and (max-width: 768px) {
    .Header-nav {
      display: none;
      flex-direction: column;
      position: absolute;
      top: 60px;
      right: 20px;
      background: #fff;
      padding: 10px;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
    }
  
    .Header-nav.open {
      display: flex;
    }
  
    .Header-menu-button {
      display: block;
    }
  }
  