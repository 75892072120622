.App {
  height: 100vh;
  text-align: center;
  background-color: rgba(235,235,235,1);
  display: flex; /* Added for centering */
  flex-direction: column; /* Aligns items vertically */
  justify-content: center; /* Centers content vertically */
  align-items: center; /* Centers content horizontally */
}

.App-logo {
  height: 50vh;
  pointer-events: none;
}

.App-header {
  padding-left: 1vh;
  background-color: rgba(235,235,235,1);
  min-height: 10vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: black;
}

.title {
  font-size: calc(10px + 2vmin);
  color: black;
  margin-bottom: 20px; /* Adds space below title */
}

.coming-soon {
  color: black;
  margin-top: 10px;
}
