/* General Container */
.contact-us-container {
    padding: 40px;
    max-width: 1200px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
  }
  
  /* Header Section */
  .contact-header {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .contact-header h1 {
    font-size: 2.5rem;
    margin-bottom: 10px;
    color: #1e1e1e;
  }
  
  .contact-header p {
    font-size: 1rem;
    color: #555;
  }
  
  /* Content Section */
  .contact-content {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  
  /* Form Section */
  .contact-form {
    flex: 1;
    min-width: 300px;
    max-width: 500px;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 30px;
    background-color: #f7f7f7;
  }
  
  .contact-form h2 {
    font-size: 1.5rem;
    margin-bottom: 20px;
    color: #1e1e1e;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 8px;
    color: #333;
  }
  
  .form-group input, .form-group textarea {
    width: 90%;
    padding: 12px 15px; /* Increased padding */
    border: 1px solid #bbb;
    border-radius: 5px;
    font-size: 1rem;
    background-color: #ffffff;
    color: #1e1e1e;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  .form-group input:focus, .form-group textarea:focus {
    outline: none;
    border-color: #2e2e2e;
    box-shadow: 0 0 4px rgba(46, 46, 46, 0.5);
  }
  
  .submit-button {
    display: block;
    width: 100%;
    padding: 12px;
    background-color: #2e2e2e; /* Dark button */
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    text-align: center;
  }
  
  .submit-button:hover {
    background-color: #1e1e1e; /* Slightly darker on hover */
  }
  
  /* Contact Info Section */
  .contact-info {
    flex: 1;
    min-width: 300px;
    max-width: 400px;
    padding: 30px;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: #f7f7f7;
  }
  
  .contact-info h2 {
    font-size: 1.5rem;
    margin-bottom: 15px;
    color: #1e1e1e;
  }
  
  .contact-info p {
    font-size: 1rem;
    margin-bottom: 10px;
    color: #333;
  }
  
  .contact-info strong {
    color: #000;
  }
  
  /* Footer Alignment Fix */
  .contact-us-container footer {
    margin-top: 30px;
    text-align: center;
    font-size: 0.875rem;
    color: #555;
  }
  